import Vue from 'vue'

import {
  Button,
  Message,
  MessageBox,
  Carousel,
  CarouselItem,
  Tree,
  Table,
  TableColumn,
  Switch,
  Pagination,
  Form,
  FormItem,
  RadioGroup,
  Radio,
  DatePicker,
  Dialog,
  Input,
  Badge,
  Upload,
  Popconfirm,
  Checkbox,
  CheckboxGroup
} from 'element-ui';





Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Tree)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Badge)
Vue.use(Upload)
Vue.use(Popconfirm)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;