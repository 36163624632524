<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
    </div> -->
    <div id="app">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </div>
</template>
<script>
import { keyWord } from "./assets/js/index.js";
export default {
  data() {
    return {
      title: "",
      description: "",
      description2: "",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: "Description", content: this.description },
        {
          name: "Keywords",
          content: this.description2,
        },
      ],
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    window.onload = function () {
      // console.log(window.sessionStorage["ISlogin"]);
      if (!window.sessionStorage["ISlogin"]) {
        // 关闭浏览器
        window.localStorage.removeItem("userInfo");
      } else {
        // 刷新
      }
    };
    window.onunload = function () {
      window.sessionStorage["ISlogin"] = true;
    };
    window.onbeforeunload = function () {
      window.sessionStorage["ISlogin"] = true;
    };
  },
  methods: {
    initData() {
      keyWord({
        siteId: window.localStorage.getItem("siteId"),
      }).then((res) => {
        // console.log(res);
        if ((res.code = 200)) {
          this.title = res.data.index_title;
          this.description = res.data.desc;
          this.description2 = res.data.keys;
        }
      });
    },
  },
};
</script>
<style>
.flex3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex4 {
  display: flex;
  align-items: center;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #999;
}
</style>
