import request from '@/utils/request'



//上传图
export function upload({data}) {
    return request({
        url: `/api/upload/upload?action=uploadimage`, // 假地址 自行替换
        method: 'post',
        data
    })
}

