import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from "store";
import { getHttp } from "../assets/js/user";
Vue.use(VueRouter);

function load(path, type = 0) {
  const dir = type ? 'components' : 'views';
  return () => import(`@/${dir}/${path}.vue`);
}

const Router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'layout',
      component: load('layout'),
      redirect: 'index',
      children: [
        {
          path: '/index',
          name: 'Home',
          component: load('Home'),
        },
      ],
    },
     {
      path: '/synthesize',
      name: 'synthesize',
      component: load('live/synthesizeDetail'),
    },
    // 非嵌套路由 必须以 / 开头
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: load('login/login'),
    // },
    {
      path: '/',
      name: 'layout2',
      component: load('layout2'),
      children: [
        {
          path: '/contact',
          name: 'contact',
          component: load('contact/contact'),
        },
        {
          path: '/live',
          name: 'Live',
          component: load('live/live'),
        }, {
          path: '/detail',
          name: 'detail',
          component: load('live/detail'),
        }, {
          path: '/detail2',
          name: 'detail2',
          component: load('live/detail2'),
        },
        {
          path: '/tiku',
          name: 'tiku',
          // redirect: '/zhangjie',
          component: load('tiku/tiku'),
          // children: [
          //   {
          //     path: '/zhangjie',
          //     name: 'zhangjie',
          //     component: load('tiku/child/zhangjie'),
          //   },
          //   {
          //     path: '/moni',
          //     name: 'moni',
          //     component: load('tiku/child/moni'),
          //   },
          //   {
          //     path: '/history',
          //     name: 'history',
          //     component: load('tiku/child/history'),
          //   },
          //   {
          //     path: '/yati',
          //     name: 'yati',
          //     component: load('tiku/child/yati'),
          //   },
          // ]
        },
        {
          path: '/course',
          name: 'course',
          component: load('course/course'),
          meta: {
            keepAlive: false
          }
        },

        {
          path: '/personal',
          name: 'personal',
          component: load('personal/personal'),
        },
        {
          path: '/establish',
          name: 'establish',
          component: load('order/establish'),
        }, {
          path: '/payment',
          name: 'payment',
          component: load('order/payment'),
        }
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: load('login/login'),
    },
    {
      path: '/notice',
      name: 'notice',
      component: load('notice/notice'),
    },
    {
      path: '/video',
      name: 'video',
      component: load('course/video'),
    },
    {
      path: '/video2',
      name: 'video2',
      component: load('live/video2'),
    },
    {
      path: '/exam',
      name: 'exam',
      component: load('exam/exam'),
    },
    {
      path: '/exam',
      name: 'exam1',
      component: load('exam/exam1'),
    },
    {
      path: '/analysis',
      name: 'analysis',
      component: load('exam/analysis'),
    },
    {
      path: '/footerLink',
      name: 'footerLink',
      component: load('footerLink'),
    },
    {
      path: '/download',
      name: 'download',
      component: load('download'),
    },
  ],
});

function except(modules, path) {
  let inPath = false;
  modules.map(n => {
    if (path.split('/')[1] == n) inPath = true;
  });

  return inPath;
}

Router.beforeEach((to, from, next) => {
  // if (to.path == "/course") {
  //   if (from.path == "/" && to.path == "/course") {
  //     console.log(11111);
  //     to.meta.keepAlive = false
  //   } else if (from.path == "/detail" && to.path == "/course") {
  //     //   console.log(to.meta.keepAlive);
  //     to.meta.keepAlive = true
  //     // }
  //     // else if (from.path == "/course") {
  //     //   from.meta.keepAlive = false
  //     // }
  //   }
  // } else {
  //   to.meta.keepAlive = false
  // }
  window.scrollTo(0, 0);
  let common = ['login']
  let host = window.location.host
  if (to.path == "/notice") {
    next();
    return
  }
  getHttp({
    host: host
  }).then((res) => {
    // console.log(res);
    if (res.code == 200) {
      // console.log(res.data.siteId)
      window.localStorage.setItem('siteId', res.data.siteId)
      // window.localStorage.setItem('siteId', 1)
    }
    next();
  });
});

export default Router;
