import axios from 'axios'
import {
	Loading,
	Message
} from "element-ui";
import router from '../router';

let loadingInstance, timer;


const service = axios.create({
	// 云校平台
	baseURL: 'https://api.hnzye.cn/',

	// k-12平台
	// baseURL: 'https://api.hnzye.com/',
	timeout: 60000,
})

service.interceptors.request.use(
	config => {
		// loadingInstance = Loading.service({
		//   fullscreen: true,
		//   text: 'Loading',
		//   spinner: 'el-icon-loading',
		//   background: 'rgba(0, 0, 0, 0.7)'
		// });
		// console.log(loadingInstance)
		// config.headers.Authorization = window.localStorage.getItem('token')
		return config;
	},
	error => {
		// do something with request error
		// console.log(error); // for debug
		return Promise.reject(error);
	}

);
service.interceptors.response.use(
	response => {
		const res = response.data;
		// if (loadingInstance) {
		//   loadingInstance.close();
		// }
		// console.log(res)
		if (res.code !== 200) {
			if (res.code == -1) {
				return Promise.reject();
			}
			Message({
				message: res.msg || 'Error',
				type: 'error',
				duration: 2 * 1000
			});
			if (res.code == 300) {
				router.push({
					path: '/notice'
				})
				return Promise.reject(new Error(res.msg || '登录拒绝'));
			}
			return Promise.reject(new Error(res.msg || '登录拒绝'));
		} else {

			return res;
		}

	},
	error => {
		// console.log("err" + error); // for debug
		// if (loadingInstance) {
		//   loadingInstance.close();
		// }
		Message({
			message: error.message,
			type: "error",
			duration: 5 * 1000
		});
		return Promise.reject(error);
	}
);
export default service
