
import request from '@/utils/request'
export const banner = (data) => request({
  url: '/api/index/banner',
  method: 'POST',
  data
});
// 顶部二级分类
export const getCateGory = (data) => request({
  url: '/api/index/top_category',
  method: 'POST',
  data
});
// 最近直播课
export const broadcast = (data) => request({
  url: '/api/index/broadcast',
  method: 'POST',
  data
});
// 首页系列课
export const indexSeries = (data) => request({
  url: '/api/index/series',
  method: 'POST',
  data
});
// 首页楼层
export const indexFloor = (data) => request({
  url: '/api/index/broadcasts',
  method: 'POST',
  data
});
// 右侧边栏
export const rightSide = (data) => request({
  url: '/api/index/index_right',
  method: 'POST',
  data
});
// 底部
export const footer = (data) => request({
  url: '/api/index/index_bottom',
  method: 'POST',
  data
});
// 联系我们
export const about = (data) => request({
  url: '/api/index/index_info',
  method: 'POST',
  data
});
// 获取关键词描述
export const keyWord = (data) => request({
  url: '/api/index/index_top',
  method: 'POST',
  data
});
// 左侧联动
export const leftSide = (data) => request({
  url: '/api/index/index_left',
  method: 'POST',
  data
});
// 获取兴趣推荐
export const getswitch = (data) => request({
  url: '/api/index/switch_category',
  method: 'POST',
  data
});
// 提交兴趣标签
export const addSwitch = (data) => request({
  url: '/api/index/add_switch_category',
  method: 'POST',
  data
});

// 底部新手指导
export const footerLink = (data) => request({
  url: '/api/index/bottom',
  method: 'POST',
  data
});